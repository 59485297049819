import './App.css';
import Simulator from './UnipessoalSimulator.js';


function App() {
  return (
    <Simulator>
    </Simulator>
  );
}

export default App;
